import {
	IEvent,
	IEventAggregationService,
	ISubscriber,
	SessionServiceReadyEvent, WebsocketServiceReadyEvent,
} from '@studyportals/student-interfaces';
import { IStudent, StudentField } from '@studyportals/studentdomain';
import { InterestType, StudentRepositoryStateType } from '../../../interfaces/enumerations';
import { AnonymousStudentProfileUpdated } from '../../../interfaces/events';
import { CatchReportAsyncException } from '../../decorators/error-decorators';
import { AnonymousStudentEventBroadcaster } from '../anonymous-student-event-broadcaster';
import { StudentClient } from '../interfaces/student-client';
import { IStudentProfileChanged } from '../student-profile-changed.inferface';
import { LocalStudentClient } from './local-student-client';
import { StudentAPIClient } from './student-api-client';

export class CachedStudentClient implements StudentClient, ISubscriber<WebsocketServiceReadyEvent | IStudentProfileChanged> {
	constructor(
		private studentAPIClient: StudentAPIClient,
		private localStudentClient: LocalStudentClient,
		private eventAggregationService: IEventAggregationService,
		private anonymousStudentEventBroadcaster: AnonymousStudentEventBroadcaster,
	) {
		this.eventAggregationService.subscribeTo(WebsocketServiceReadyEvent.EventType, this, true);

	}

	@CatchReportAsyncException
	public async notify(event: WebsocketServiceReadyEvent | IStudentProfileChanged): Promise<void> {
		if (event.eventType === WebsocketServiceReadyEvent.EventType) {
			this.handleWebsocketServiceReadyEvent(event as WebsocketServiceReadyEvent);
		}

		if (event.eventType === 'StudentProfileChanged') {
			await this.handleWebsocketEvent(event as IStudentProfileChanged);
		}
	}

	private handleWebsocketServiceReadyEvent(event: WebsocketServiceReadyEvent): void {
		event.eventAggregationService.subscribeToWebSocketEvent('StudentProfileChanged', this);
	}

	private async handleWebsocketEvent(event: IStudentProfileChanged): Promise<void> {
		const changes: IStudent = this.transformStudentProfileChangedEventIntoStudentData(event);
		const timestamp: Date = new Date(event.eventTimestamp);
		await this.updateDataFromEvent(changes);

		const anonymousEvent = new AnonymousStudentProfileUpdated(timestamp, StudentRepositoryStateType.ONLINE, changes);
		this.anonymousStudentEventBroadcaster.broadcastProfileUpdatedEvent(anonymousEvent);
	}

	private isForceUpdateField(studentField: StudentField): boolean {
		return [
			StudentField.DISCIPLINES,
			StudentField.INTERESTS_COUNTRIES,
			StudentField.INTERESTS_DISCIPLINES,
			StudentField.TUITION_BUDGET,
			StudentField.LIVING_BUDGET,
		].includes(studentField);
	}

	private async updateDataFromEvent(studentData: IStudent): Promise<void> {
		const studentFieldsWithPartialData: StudentField[] = [];

		Object.keys(studentData).forEach((studentField: StudentField) => {
			if (studentData[studentField] instanceof Object || this.isForceUpdateField(studentField)) {
				studentFieldsWithPartialData.push(studentField);
			}
		});

		const studentDataForObjects = await this.studentAPIClient.getData(studentFieldsWithPartialData);
		await this.localStudentClient.setData({...studentData, ...studentDataForObjects});
	}

	private transformStudentProfileChangedEventIntoStudentData(event: IStudentProfileChanged): IStudent {
		const changes: IStudent = {};

		if (event.updated) {
			Object.keys(event.updated).forEach((key) => {
				changes[key] = event.updated[key];
			});
		}

		if (event.deleted) {
			Object.keys(event.deleted).forEach((key) => {
				changes[key] = undefined;
			});
		}

		return changes;
	}

	public async addDisciplines(ids: number[]): Promise<void> {
		await this.studentAPIClient.addDisciplines(ids);
		await this.localStudentClient.addDisciplines(ids);
	}

	public async addInterest(type: InterestType, ids: number[]): Promise<void> {
		await this.studentAPIClient.addInterest(type, ids);
		await this.localStudentClient.addInterest(type, ids);
	}

	public async getData(studentFields: StudentField[]): Promise<IStudent> {
		const localData = await this.localStudentClient.getData(studentFields);
		const localKeys = Object.keys(localData);

		const missingFields = studentFields.filter((field) => {
			return !localKeys.includes(field);
		});

		if (missingFields.length > 0) {
			const remoteData = await this.studentAPIClient.getData(missingFields);
			await this.localStudentClient.setData(remoteData);
			return {...localData, ...remoteData};
		}

		return localData;
	}

	public async removeDisciplines(ids: number[]): Promise<void> {
		await this.studentAPIClient.removeDisciplines(ids);
		await this.localStudentClient.removeDisciplines(ids);
	}

	public async removeInterest(type: InterestType, ids: number[]): Promise<void> {
		await this.studentAPIClient.removeInterest(type, ids);
		await this.localStudentClient.removeInterest(type, ids);
	}

	public async setData(studentData: IStudent): Promise<void> {
		await this.studentAPIClient.setData(studentData);
		await this.localStudentClient.setData(studentData);
	}

	public clearCache(): Promise<void> {
		return this.localStudentClient.cleanUp();
	}

}
