import { IEventAggregationService } from '@studyportals/student-interfaces';
import { AnonymousStudentServiceReady } from '../interfaces/events';
import { AnonymousStudentService } from './application/anonymous-student-service';
import { StudentRepository } from './domain/student-repository';

const eventAggregationService: IEventAggregationService = window['EventAggregationService'];
const studentRepository = new StudentRepository(eventAggregationService, window['SessionService']);
const anonymousStudent = new AnonymousStudentService(studentRepository);
window['AnonymousStudent'] = anonymousStudent;
studentRepository.initialize();

const event = new AnonymousStudentServiceReady(anonymousStudent);
eventAggregationService.publishTo(AnonymousStudentServiceReady.EventType, event);
