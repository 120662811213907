import { IEvent } from '@studyportals/student-interfaces';
import { IAnonymousStudentService } from '../application';
import { EventType } from '../enumerations';

export class AnonymousStudentServiceReady implements IEvent {
	public static EventType: string = EventType.AnonymousStudentServiceReady;
	public eventType: string = AnonymousStudentServiceReady.EventType;

	public readonly timestamp: Date;
	public readonly anonymousStudentService: IAnonymousStudentService;

	constructor(anonymousStudentService: IAnonymousStudentService) {
		this.timestamp = new Date();
		this.anonymousStudentService = anonymousStudentService;
	}

}
