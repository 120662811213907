import { IEvent } from '@studyportals/student-interfaces';
import { EventType } from '../enumerations/event-type';
import { StudentRepositoryStateType } from '../enumerations/student-repository-state-type';

export class AnonymousStudentProfileSynced implements IEvent {
	public static EventType: string = EventType.AnonymousStudentProfileSynced;
	public eventType: string = AnonymousStudentProfileSynced.EventType;

	constructor(
		public readonly timestamp: Date,
		public readonly state: StudentRepositoryStateType,
	) {

	}

}
