import { IEvent } from '@studyportals/student-interfaces';
import { IStudent } from '@studyportals/studentdomain';
import { EventType, StudentRepositoryStateType } from '../enumerations';

export class AnonymousStudentProfileUpdated implements IEvent {
	public static EventType: string = EventType.AnonymousStudentProfileUpdated;
	public eventType: string = AnonymousStudentProfileUpdated.EventType;

	constructor(
		public readonly timestamp: Date,
		public readonly state: StudentRepositoryStateType,
		public readonly changes: IStudent,
	) {
	}
}
